@import '../variables';

.item {
  display: flex;
  justify-content: space-between;
  align-items: center;

  &Hint {
    color: $gray400;
    margin-left: 0.5rem;
  }

  &Danger {
    color: $danger;
  }
}
