// Grayscale
$black: hsl(0, 0%, 15%);
$gray900: hsl(0, 0%, 20%);
$gray800: hsl(0, 0%, 33%);
$gray700: hsl(0, 0%, 40%);
$gray600: hsl(0, 0%, 50%);
$gray500: hsl(0, 0%, 60%);
$gray400: hsl(0, 0%, 73%);
$gray300: hsl(0, 0%, 80%);
$gray200: hsl(0, 0%, 87%);
$gray100: hsl(0, 0%, 93%);
$gray50: hsl(0, 0%, 98%);
$white: hsl(0, 0%, 99.8%);

// Main colors
$accent: hsl(209, 100%, 55%);
$success: hsl(94, 64%, 63%);
$warning: hsl(40, 96%, 53%);
$danger: hsl(0, 86%, 42%);
$info: hsl(189, 100%, 45%);
$online: hsl(115, 100%, 36%);
$shared: hsl(229, 100%, 30%);
$syndicated: hsl(250, 100%, 70%);

// Borders
$borderColor: $gray100;
$borderLighterColor: $gray100;
$borderDarkerColor: $gray300;

// Text
$text: $gray800;
$textLight: $gray400;

// Highlight
$highlight: rgba($accent, 0.2);
$highlightItem: rgba($accent, 0.08);

// Shadows
$boxShadow: rgba($black, 0.33);
$dragShadow: rgba($black, 0.25);

// Content Links
$linkInternalColor: hsl(120, 38%, 57%);
$linkExternalColor: hsl(204, 38%, 57%);

// Dynamic fields
$newField: hsl(90, 50%, 60%);
$existingField: hsl(210, 100%, 80%);

// Content Diff and Revisions
$insColor: hsl(123, 51%, 73%);
$delColor: hsl(0, 89%, 82%);
$currentRevisionColor: hsl(221, 86%, 71%);
$versionColor: $textLight;

// Statuses
$deleted: hsl(20, 100%, 50%);
$draft: hsl(0, 0%, 67%);
$new: hsl(110, 60%, 67%);
$new_rejected: hsl(76, 100%, 37%);
$approved: hsl(206, 100%, 53%);
$remove: hsl(32, 100%, 50%);
$remove_rejected: hsl(32, 100%, 43%);
$reviewing: hsl(300, 100%, 37%);
$update: hsl(180, 100%, 43%);
$update_rejected: hsl(180, 100%, 37%);

// Others
$note: hsl(60, 100%, 93%);
