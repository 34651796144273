@import '../../../variables';

.wrapper {
  display: flex;
  flex-direction: column;
  align-content: stretch;
  justify-content: stretch;
  flex-grow: 1;
  height: 100vh;
}

.header {
  position: fixed;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: $headerHeight;
  background-color: $gray50;
  z-index: 1200;
  border-bottom: $border;
  padding: 0 1rem 0 0;
  user-select: none;
}

.title {
  display: flex;
  justify-content: center;
  align-items: center;
}

.controls {
  display: flex;
  justify-content: center;
  align-items: center;
}

.body {
  display: flex;
  flex-direction: column;
  justify-content: stretch;
  flex-grow: 1;
}

.sider {
  position: fixed;
  display: flex;
  top: $headerHeight;
  bottom: 0;
  width: $siderWidth;
  flex-direction: column;
  justify-content: space-between;
  align-items: stretch;
  transition: left $transitionConf;
  border-right: $border;
  background-color: $white;
  z-index: 999;
  overflow-x: hidden;
  overflow-y: auto;

  .sider--open & {
    left: 0;
  }

  .sider--closed & {
    left: -$siderWidth;
  }

  &Menu {
    display: flex;
    width: $siderWidth - 1px;
    overflow: auto;
    overflow-x: hidden;
  }

  &Toggle {
    margin: 0 0.25rem;

    &Icon {
      transition: color 0.3s ease-in-out;

      &Open {
        color: rgba($accent, 0.8);
      }
    }
  }
}

.content {
  display: flex;
  flex-direction: column;
  transition: margin-left $transitionConf;
  justify-content: stretch;
  padding-top: $headerHeight;
  flex-grow: 1;

  &Overlay {
    transition: opacity $transitionConf;
    opacity: 0;

    .sider--open & {
      background-color: rgba($white, 0.8);
      position: fixed;
      opacity: 1;
      top: 0;
      bottom: 0;
      right: 0;
      left: 0;
      z-index: 998;
    }
  }
}

@media (min-width: $md) {
  .sider--open {
    .content {
      margin-left: $siderWidth;
    }

    .contentOverlay {
      display: none;
    }
  }
}
