@import '../../../variables';

.wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 2rem 0;

  @media (min-height: $md) {
    height: 100vh;
  }
}

.header {
  display: flex;
  flex-shrink: 0;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin: 1rem 0;
}

.content {
  min-width: 18rem;
  max-width: 20rem;
}
