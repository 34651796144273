@import './colors';
@import './mediaQueries';

$headerHeight: 4rem;
$siderWidth: 200px;
$siderToggleWidth: 2rem;
$border: 1pt solid $borderColor;
$borderLighter: 1pt solid $borderLighterColor;
$borderTransparent: 1pt solid transparent;
$borderRadius: 4px;
$transitionConf: 0.3s ease-in-out;
$draggingShadow: 0 0.1rem 0.5rem $dragShadow;
