@import './variables';

/* Place only global styles here */
body {
  margin: 0;
  font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
  font-size: 12px;
  line-height: 1.5;
  background-color: $white;
  color: $text;
}

// Avoids zoom in iOS when editing fields
@media (-webkit-min-device-pixel-ratio: 0) {
  select,
  textarea,
  input {
    font-size: 16px !important;
  }
}

@media (min-width: $sm) {
  body {
    font-size: 13px;
  }
}

@media (min-width: $md) {
  body {
    font-size: 14px;
  }
}
