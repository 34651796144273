$margin: 0.5rem;

.wrap {
  text-align: center;
}

.marginRight {
  margin-right: $margin;
}

.marginLeft {
  margin-left: $margin;
}
