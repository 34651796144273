@import './colors';

/* Ant Design Overrides */
/* NOTE: overriding an existing style requires an !important flag */

.ant-notification {
  z-index: 1800;
}

.ant-modal-mask {
  z-index: 2000 !important;
}

.ant-modal-wrap {
  z-index: 2001 !important;
}

.ant-drawer {
  z-index: 2200 !important;
}

.ant-select-dropdown,
.ant-dropdown {
  z-index: 2500 !important;
}
.ant-dropdown-menu-submenu {
  z-index: 2510 !important;
}
.ant-tooltip {
  z-index: 2600 !important;
}
.ant-popover {
  z-index: 2700 !important;
}

.ant-menu-inline,
.ant-menu-vertical,
.ant-menu-vertical-left {
  border-right: none !important;
}

.ant-menu-submenu-title {
  display: flex !important;
  align-items: center;
}

.ant-menu.ant-menu-sub > .ant-menu-item-divider {
  margin-left: 1.4rem;
}

.ant-calendar-picker {
  display: block !important;
}
.ant-calendar-picker-input {
  padding-right: 2rem !important;
}

.ant-list-item-meta {
  flex: auto !important;
  font-size: inherit !important;
}

.ant-tabs {
  overflow: visible !important;
}

.ant-form-explain,
.ant-form-extra {
  margin-top: 0 !important;
}

.ant-form-item-control {
  line-height: unset !important;
}

.ant-popover-inner {
  box-shadow: 0 1px 8px $boxShadow !important;
}

.ant-select-tree {
  li {
    .ant-select-tree-node-content-wrapper {
      border-radius: 0.25rem !important;

      &.ant-select-tree-node-selected {
        background-color: $accent !important;
        color: white;
        padding-left: 0.5rem;

        &:hover {
          background-color: lighten($accent, 10%) !important;
        }
      }
    }
  }
}

.ant-input-number {
  width: 100% !important;
}

.ant-btn > i,
.ant-btn > span {
  display: initial !important;
}

.ant-pagination-total-text {
  display: block !important;
  margin-right: 0 !important;
  height: auto !important;
}
